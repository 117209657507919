//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LandingThanksModal from '@/components/modals/LandingThanksModal'
import { addEmailToSubscribers } from '@/utils/subscription/subscriber'
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
import footerMixin from '@/mixins/footer.mixin'
import AlgOfferLink from '@/components/common/links/AlgOfferLink'
import AlgMotkaLink from '@/components/common/links/AlgMotkaLink'
import AlgCopyrightText from '@/components/common/AlgCopyrightText'

export default {
  name: 'PartnerFooterVue',
  components: {
    AlgCopyrightText,
    AlgMotkaLink,
    AlgOfferLink,
    LandingThanksModal,
  },
  mixins: [footerMixin],
  data() {
    return {
      emailIsIncorrect: false,
      subscribeEmail: null,
      isThanksMessageActive: false,
    }
  },
  computed: {
    ...mapState(['i18n']),
    contactsText() {
      let contactText =
        this.$getTextMainSetting('footerContactsText').toString()

      contactText = contactText.replace(
        '#EMAIL#',
        '<a href="mailto:' +
          this.$getTextMainSetting('branchEmail') +
          '"><bdi>' +
          this.$getTextMainSetting('branchEmail') +
          '</bdi></a>'
      )

      contactText = contactText.replace(
        '#PHONE#',
        '<a class="tel" href="tel:+' +
          this.$getTextMainSetting('branchPhoneCanonical') +
          '"><bdi>' +
          this.$getTextMainSetting('branchPhone') +
          '</bdi></a>'
      )

      return contactText
    },
    isShowRussianBlocks() {
      return this.$getTextMainSetting('isRussianFranchise')
    },
  },
  methods: {
    getLogo() {
      if (this.$i18n.locale === 'ru') {
        return require(`~/assets/img/partner/main/header/logo-ru.svg`)
      }
      return require(`~/assets/img/partner/main/header/logo-en.png`)
    },
    async onSubmit() {
      const currentDomainData = this.$getCurrentDomainData()
      const result = await addEmailToSubscribers(
        this.subscribeEmail,
        currentDomainData
      )
      if (result) {
        this.isThanksMessageActive = true
      } else {
        this.emailIsIncorrect = true
      }
    },
    handleClick(e) {
      if (e.target.matches('.partner-footer__call .tel')) {
        this.onClickPhoneNumber()
      }
    },
    onClickPhoneNumber() {
      eventBus.$emit('click-phone-number')
    },
  },
}
